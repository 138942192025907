import React from "react"
import styled from "styled-components"
import Card from "./Card"
import Merge from "./icons/merge.svg"
import Rotate from "./icons/rotate.svg"
import Extract from "./icons/extract.svg"
import Delete from "./icons/delete.svg"
import Split from "./icons/split.svg"
import Protect from "./icons/protect.svg"
import Image from "./icons/toPNG.svg"
import Folder from "./icons/folder.svg"
import Html from "./icons/html.svg"

const Wrapper = styled.div`
  display: flex;
  width: 90vw;
  flex-wrap: wrap;
  max-width: 1080px;
  @media (min-width: 1186px) {
    min-width: 1080px;
  }
  border-top: 1px dashed #cfe2ff;
  border-left: 1px dashed #cfe2ff;
`

const Title = styled.h2`
  width: 100%;
  height: 66px;
  padding: 16px;
  color: #333333;
  font-size: 25px;
  line-height: 33px;
  display: flex;
  align-items: center;
  background: #f5f6fc;
  margin: 0;

  border: 1px dashed #cfe2ff;
  border-bottom: none;
  border-top: none;
  ${props => props.top && "border-top: 1px dashed #cfe2ff;"}
`
const MainHolder = styled.div`
  display: flex;
  flex-direction: column;
`
function Tools() {
  return (
    <MainHolder>
      <Title top>Popular</Title>
      <Wrapper>
        <Card
          title="PDF to JPG"
          description="Convert a PDF to JPG."
          cta="Convert my PDF"
          Svg={Image}
          link="/pdf-to-jpg/"
        />
        <Card
          title="JPG to PDF"
          description="Convert a JPG image to PDF."
          Svg={Image}
          cta="Convert my JPG"
          link="/jpg-to-pdf/"
        />
        <Card
          title="Merge PDF"
          description="Combine two or more PDFs into a single PDF."
          Svg={Merge}
          cta="Merge my PDFs"
          link="/merge-pdf/"
        />
        <Card
          title="PNG to PDF"
          description="Convert a PNG image to PDF."
          Svg={Image}
          cta="Convert my PNG"
          link="/png-to-pdf/"
        />
        <Card
          title="PDF to PNG"
          description="Convert a PDF to PNG."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-png/"
        />
        <Card
          title="Rotate PDF"
          description="Rotate individual PDF pages or entire documents."
          Svg={Rotate}
          cta="Rotate my PDF"
          link="/rotate-pdf/"
        />
      </Wrapper>
      <Title top>View & Edit</Title>
      <Wrapper>
        <Card
          title="Merge PDF"
          description="Combine two or more PDFs into a single PDF."
          cta="Merge my PDFs"
          Svg={Merge}
          link="/merge-pdf/"
        />
        <Card
          title="Rotate PDF"
          description="Rotate individual PDF pages or entire documents."
          cta="Rotate my PDF"
          Svg={Rotate}
          link="/rotate-pdf/"
        />
        <Card
          title="Extract PDF Pages"
          description="Extract the exact page you need from a PDF document."
          Svg={Extract}
          cta="Extract Now"
          link="/extract-pdf-pages/"
        />
        <Card
          title="Delete PDF Pages"
          description="Delete pages from a PDF."
          Svg={Delete}
          cta="Delete Now"
          link="/delete-pdf-pages/"
        />
        <Card
          title="Rearrange PDF Pages"
          description="Rearrange PDF Pages in a PDF."
          Svg={Image}
          cta="Rearrange my PDF"
          link="/rearrange-pdf-pages/"
        />
        <Card
          title="Split PDF"
          description="Split a PDF into separate files. Split at a specific page, or after every page."
          Svg={Split}
          cta="Split my PDF"
          link="/split-pdf/"
        />
      </Wrapper>
      <Title>Convert from PDF</Title>
      <Wrapper>
        <Card
          title="PDF to PNG"
          description="Convert a PDF to PNG."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-png/"
          width={"25%"}
        />
        <Card
          title="PDF to JPG"
          description="Convert a PDF to JPG."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-jpg/"
          width={"25%"}
        />
        <Card
          title="PDF to TIFF"
          description="Convert a PDF to TIFF."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-tiff/"
          width={"25%"}
        />
        <Card
          title="PDF to BMP"
          description="Convert a PDF to BMP."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-bmp/"
          width={"25%"}
        />
      </Wrapper>
      <Title>Convert to PDF</Title>
      <Wrapper>
        <Card
          title="TIFF to PDF"
          description="Convert TIFF to PDF."
          Svg={Image}
          cta="Convert my TIFF"
          link="/tiff-to-pdf/"
          width={"25%"}
        />

        <Card
          title="PNG to PDF"
          description="Convert a PNG image to PDF."
          Svg={Image}
          cta="Convert my PNG"
          link="/png-to-pdf/"
          width={"25%"}
        />
        <Card
          title="JPG to PDF"
          description="Convert a JPG image to PDF."
          Svg={Image}
          cta="Convert my JPG"
          link="/jpg-to-pdf/"
          width={"25%"}
        />

        <Card
          title="BMP to PDF"
          description="Convert a BMP image to PDF."
          Svg={Image}
          link="/bmp-to-pdf/"
          cta="Convert my BMP"
          width={"25%"}
        />
      </Wrapper>
      <Title>Security</Title>
      <Wrapper>
        <Card
          title="Password Protect PDF"
          description="Protect a PDF with a password to prevent unwanted viewing and opening."
          Svg={Protect}
          cta="Protect my PDF"
          link="/password-protect-pdf/"
        />
        <Card status={""} width={"66.66%"} />
      </Wrapper>
      <Title>All Tools</Title>

      <Wrapper>
        <Card
          title="Merge PDF"
          description="Combine two or more PDFs into a single PDF."
          Svg={Merge}
          cta="Merge my PDFs"
          link="/merge-pdf/"
        />
        <Card
          title="Rotate PDF"
          description="Rotate individual PDF pages or entire documents."
          Svg={Rotate}
          cta="Rotate my PDF"
          link="/rotate-pdf/"
        />
        <Card
          title="Extract PDF Pages"
          description="Extract the exact page you need from a PDF document."
          Svg={Extract}
          cta="Extract Now"
          link="/extract-pdf-pages/"
        />
        <Card
          title="Delete PDF Pages"
          description="Delete pages from a PDF."
          Svg={Delete}
          cta="Delete Now"
          link="/delete-pdf-pages/"
        />
        <Card
          title="Split PDF"
          description="Split a PDF into separate files. Split at a specific page, or after every page."
          Svg={Split}
          cta="Split my PDF"
          link="/split-pdf/"
        />
        <Card
          title="Password Protect PDF"
          description="Protect a PDF with a password to prevent unwanted viewing and opening."
          Svg={Protect}
          cta="Protect my PDF"
          link="/password-protect-pdf/"
        />
        <Card
          title="PDF to PNG"
          description="Convert a PDF to PNG."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-png/"
        />
        <Card
          title="PDF to JPG"
          description="Convert a PDF to JPG."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-jpg/"
        />
        <Card
          title="PDF to TIFF"
          description="Convert a PDF to TIFF."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-tiff/"
        />
        <Card
          title="PDF to BMP"
          description="Convert a PDF to BMP."
          Svg={Image}
          cta="Convert my PDF"
          link="/pdf-to-bmp/"
        />
        <Card
          title="PDF to Word"
          description="Convert a PDF to a Microsoft Word document."
          cta="Convert my PDF"
          Svg={Folder}
          link="/pdf-to-word/"
        />
        <Card
          title="PDF to Excel"
          description="Convert a PDF to Excel."
          cta="Convert my PDF"
          Svg={Folder}
          link="/pdf-to-excel/"
        />
        <Card
          title="PDF to PowerPoint"
          description="Convert a PDF to PowerPoint."
          Svg={Folder}
          cta="Convert my PDF"
          link="/pdf-to-powerpoint/"
        />
        <Card
          title="PDF to HTML"
          description="Convert a PDF to HTML."
          Svg={Html}
          cta="Convert my PDF"
          link="/pdf-to-html/"
        />
        <Card
          title="TIFF to PDF"
          description="Convert TIFF to PDF."
          Svg={Image}
          cta="Convert my TIFF"
          link="/tiff-to-pdf/"
        />

        <Card
          title="PNG to PDF"
          description="Convert a PNG image to PDF."
          Svg={Image}
          cta="Convert my PNG"
          link="/png-to-pdf/"
        />
        <Card
          title="JPG to PDF"
          description="Convert a JPG image to PDF."
          Svg={Image}
          cta="Convert my JPG"
          link="/jpg-to-pdf/"
        />
        <Card
          title="Rearrange PDF Pages"
          description="Rearrange PDF Pages in a PDF."
          Svg={Image}
          cta="Rearrange my PDF"
          link="/rearrange-pdf-pages/"
        />
        {/* <Card title="" description="" Svg={"none"} link="" /> */}
        {/* <Card status={"Coming Soon"} /> */}
      </Wrapper>
    </MainHolder>
  )
}

export default Tools
