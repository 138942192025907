import React from "react"
import Minus from "../../../../icons/tools/lp/minus.svg"
import Plus from "../../../../icons/tools/lp/plus.svg"

import {
  Answer,
  Bacground,
  Expand,
} from "../../ToolsStyles/FrequentlyAskedQuestionsStyled"

function Question({ question, answer, active, id, setActive }) {
  return (
    <div>
      <Bacground onClick={() => setActive(id)}>
        <Expand>{question} </Expand>
        {active == id ? <Minus /> : <Plus />}
      </Bacground>
      {active == id && (
        <Bacground>
          <Answer>{answer}</Answer>
        </Bacground>
      )}
    </div>
  )
}

export default Question
