import React from "react"
import Seo from "../components/seo"

import RelatedGuides from "../components/tools/landingPage/RelatedGuide/RelatedGuides"
import BlogSection from "../components/tools/landingPage/BlogSection/BlogSection"
import FrequentlyAskedQuestions from "../components/tools/landingPage/FrequentlyAskedQuestionsStyled/FrequentlyAskedQuestions"
import Background from "../images/tools/lp/background-footer.png"
import Background1 from "../images/tools/lp/background-header.png"
import Background2 from "../images/tools/lp/background-middle.png"
import styled from "styled-components"
import Hero from "../components/tools/landingPage/Hero"
import Tools from "../components/tools/landingPage/Tools"

const Gradient = styled.div`
  width: 100vw;
  background-image: url(${Background});
  transform: translateX(-5vw);
  background-size: contain;
  background-size: 100% 100%;

  background-position: bottom;
  padding: 0 5vw;
  background-repeat: no-repeat;
`

const Gradient1 = styled.div`
  width: 100vw;
  background-image: url(${Background1});
  transform: translateX(-5vw);
  background-size: contain;
  background-size: 100% 100%;

  background-position: top;

  background-repeat: no-repeat;
  padding: 0 5vw;
`

const Gradient2 = styled.div`
  width: 100vw;
  /* background-image: url(${Background2}); */
  transform: translateX(-5vw);
  /* background-size: contain; */
  background-size: 100% 100%;

  background-position: bottom;

  background-repeat: no-repeat;
  padding: 0 5vw;
`

const Section = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  transform: translateX(-5vw);
  align-items: stretch;
`
const Padding = styled.div`
  min-width: 5vw;
  width: 5vw;
  z-index: 10;
  background: white;
  @media (min-width: 1187px) {
    width: 100%;
  }

  height: auto;
  border-top: 1px dashed #cfe2ff;
  border-bottom: 1px dashed #cfe2ff;

  ${props => props.top == false && "border-top: none;"}
  ${props => props.bottom == false && "border-bottom: none;"}
  ${props => props.side && "border-left: 1px dashed #cfe2ff;"}
  ${props => props.side && "border-right: 1px dashed #cfe2ff;"}
  ${props => props.white == false && "background: none;"}
`

const MergeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  padding: 0 5vw;
  background: white;
`
const PaddingBetween = styled.div`
  border-left: 1px dashed #cfe2ff;
  border-right: 1px dashed #cfe2ff;
  height: 64px;
  max-width: 1080px;
  width: 90vw;
  margin: auto;
`
const MissingMargin = styled.div`
  height: 32px;
  width: 100%;
`
function Split() {
  return (
    <>
      <MergeWrapper>
        {" "}
        <Seo
          title="Online Tools"
          description="PDF Pro's suite of free, online PDF tools work directly in your browser, on almost any device. No limits, no sign-up required."
        />
        {/*  */}
        <Gradient1>
          <Section>
            <Padding top={false} white={false} />
            <Hero />
            <Padding top={false} white={false} />
          </Section>
          <PaddingBetween />
          <Section>
            <Padding white={false} />
            <Tools />
            <Padding white={false} />
          </Section>
        </Gradient1>
        {/* <Gradient2>
          <PaddingBetween />
          <Section>
            <Padding />
            <HowItWorksTitle />
            <Padding />
          </Section>
          <Section>
            <Padding top={false} />
            <HowItWorks />
            <Padding top={false} />
          </Section>
        </Gradient2> */}
        <PaddingBetween />
        <Gradient2>
          <Section>
            <Padding />
            <RelatedGuides />
            <Padding />
          </Section>
          <PaddingBetween />
          <Section>
            <Padding />
            <BlogSection />
            <Padding />
          </Section>
        </Gradient2>
        <PaddingBetween />
        {/* <Gradient>
          <PaddingBetween />
          <Section>
            <Padding white={false} />
            <FrequentlyAskedQuestions />
            <Padding white={false} />
          </Section>
          <PaddingBetween />
        </Gradient> */}
      </MergeWrapper>
    </>
  )
}

export default Split
