import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { CanvasStyle } from "../Styles"

const Wrapper = styled(CanvasStyle)`
  min-height: 0px;
  align-items: flex-start;
`

const Title = styled.div`
  font-size: 38px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #333333;
  font-weight: 700;
  margin-top: 24px;
`

const SubTitle = styled.h2`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #425466;
  margin-top: 24px;
`

const Message = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 22px;
  color: #425466;
  margin-top: 24px;
`
function Hero() {
  return (
    <Wrapper>
      {/* <StaticImage src="./Assets/logo.png" alt="logo" /> */}
      <Title>Online Tools</Title>
      <SubTitle>Easily works with PDFs Online for Free</SubTitle>
      <Message>Free and works on all systems, from your browser</Message>
    </Wrapper>
  )
}

export default Hero
