import React from "react"
import {
  BlogCard,
  CarousellLeft,
  CarousellRight,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
  BlogFlex,
  Arrow,
} from "../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../ToolBlogImages/BlogImage1.avif"
import BlogImage2 from "../../ToolBlogImages/Extract-Text-Blog-image.png"
import BlogImage3 from "../../merge/MergeAssets/BlogImage3.png"
import BlogImage4 from "../../ToolBlogImages/Combiner.png"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-split-pdf-pages/")}
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>
            How to Split PDF Pages for Free on Any Device (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to split PDF pages with our step-by-step guide. Free,
            online, or with a PDF editor, we cover all these, for Windows & Mac.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-extract-pages-from-pdf/")
          }
        >
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>How to Extract Pages from PDF (2023)</BlogTitle>
          <BlogDescription>
            Have you ever needed to extract specific pages from a PDF document?
            This guide has everything you need to extract PDF pages like a pro.
          </BlogDescription>
          <BlogReadTime>7 min read</BlogReadTime>
        </BlogCard>

        <BlogCard onClick={() => navigate("/blog/guides/how-to-rotate-a-pdf/")}>
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>How to Rotate a PDF in 2023</BlogTitle>
          <BlogDescription>
            Whether you need to rotate a single page of one document or an
            entire PDF document, we’ll show you the best methods to rotate PDFs
            on your device.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>

        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-combine-pdf-files/")}
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>
            How to Combine PDF Files in 2023 (Free & Online)
          </BlogTitle>
          <BlogDescription>
            Learn everything you need to combine PDF files on Windows, Mac — Any
            device! Free and paid tools, online & desktop.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
