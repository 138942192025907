import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Arrow from "./icons/arrowRight.svg"

const Wrapper = styled(Link)`
  width: 33.333%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  padding-left: 0;
  gap: 12px;
  min-height: 184px;
  border: 1px dashed #cfe2ff;
  border-left: none;
  border-top: none;
  background: white;
  cursor: pointer;
  transition: 0.25s all;
  z-index: 1000;

  :hover {
    ${props =>
      props.status != "Coming Soon" &&
      props.status != "" &&
      `
    transform: scale(1.1) translateY(-16px);
    border: 1px solid #6361ff;
    box-shadow: 0px 27px 35.1px -6.5px rgba(50, 50, 93, 0.14),
      0px 10.4px 20.8px -10.4px rgba(0, 0, 0, 0.14);
    z-index: 1001;

    .title {
      border-left: none;
    }

    .cta {
      opacity: 1;
    }`}
  }

  text-decoration: none;
  ${props =>
    props.status == "Coming Soon" &&
    `
    justify-content: center;
    align-items: center;
    .title {
      border-left: none;
    }
    `}

  ${props => props.width && `width: ${props.width};`}


  @media (max-width: 860px) {
    width: 50%;
  }
  @media (max-width: 530px) {
    width: 100%;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
`
const Title = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0b263f;
  border-left: 2px solid #6361ff;
  padding-left: 16px;
  font-weight: 600;
`

const SubTitle = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #425466;
  padding-left: 16px;
`
const Cta = styled.div`
  font-weight: 600;
  color: #6361ff;
  opacity: 0;
  padding-left: 16px;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

function Card({ Svg, title, description, link, status, width, cta }) {
  return status == "Coming Soon" || status == "" ? (
    <Wrapper status={status} width={width}>
      <Title className="title">
        {status == "Coming Soon" ? "More coming soon.." : status}
      </Title>
    </Wrapper>
  ) : (
    <Wrapper to={link} width={width}>
      <Row>
        <Svg /> {status && <div>{status}</div>}
      </Row>
      <Title className="title">{title}</Title>
      <SubTitle>{description}</SubTitle>
      <Cta className="cta">
        {cta} <Arrow />
      </Cta>
    </Wrapper>
  )
}

export default Card
